import styled, { css } from 'styled-components'

import { Button } from '@/components'
import { media } from '@/styles/media'

import { imageTextFlex } from '../Landing/Landing.styled'

export const MaxWidthContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const MainTitle = styled.p<{
  marginTop?: number
  marginBottom?: number
  hasNoMarginBottomMobile?: boolean
}>`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.boldest};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}rem` : '0rem')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}rem` : '4rem'};

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
      padding: 0 1rem;
    `,
  )}

  ${({ hasNoMarginBottomMobile }) => {
    return media.mobile(
      () => css`
        margin-bottom: ${hasNoMarginBottomMobile ? '0rem' : '4rem'};
      `,
    )
  }}
`
export const LinesContainer = styled.div`
  min-width: 22rem;
  max-width: 28rem;
  margin: auto;

  div {
    text-align: center;
  }
`

export const ButtonContainer = styled.button`
  ${media.mobile(
    () => css`
      padding: 0 1rem;
      width: 100%;
    `,
  )};
`

export const ImageTextFlexContainer = styled.div`
  ${imageTextFlex()};
`

export const TrialButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 24px;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  line-height: 1.5;
  margin: 1rem auto;
  border-radius: 2rem;

  span {
    display: block;
    font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  }
`
