import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import hamburger from '@/assets/images/landing/hamburger.png'
import logo from '@/assets/images/logo-with-rocket.png'
import { schoolioBookStoreUrl } from '@/core/const'
import { throttle } from '@/core/utils/throttle'
import { AuthRoute } from '@/pages/auth/authRoutes'

import {
  GetHelp,
  HamburgerBtn,
  HamburgerLogo,
  Hr,
  Link,
  Links,
  Logo,
  Main,
  NavSection,
  NotSureText,
  SignUpLink,
} from './Nav.styled'

export function Nav() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [isNavCardAbsolute, setIsNavCardAbsolute] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation('landing')

  const isLanding = pathname === AuthRoute.Landing
  const isLogin = pathname === AuthRoute.SignIn

  const onScroll = (event: Event) => {
    if (isNavCardAbsolute) {
      return
    }

    const target = event.target as HTMLBodyElement

    const threshold = 76

    setIsNavCardAbsolute(target.scrollTop > threshold)
  }

  const throttledOnScroll = useMemo(() => throttle(onScroll, 25), [])

  useEffect(() => {
    document.body.addEventListener('scroll', throttledOnScroll)
    return () => {
      document.body.removeEventListener('scroll', throttledOnScroll)
    }
  }, [])

  const openBookStore = () => {
    window.open(schoolioBookStoreUrl, '_self')
  }

  return (
    <NavSection isAbsolute={isNavCardAbsolute} isNavExpanded={isNavExpanded}>
      <Main isAbsolute={isNavCardAbsolute}>
        <Logo src={logo} alt="schoolio logo" onClick={openBookStore} />

        <HamburgerBtn
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
          <HamburgerLogo src={hamburger} alt="menu icon" />
        </HamburgerBtn>

        <Links isNavExpanded={isNavExpanded}>
          <Link href="/pricing">{t('pricing')}</Link>
          <Hr isNavExpanded={isNavExpanded} />

          <Link target="_blank" href={`${schoolioBookStoreUrl}course-catalog/`}>
            {t('courses')}
          </Link>

          {isLanding && <SignUpLink href="/sign-in">{t('logIn')}</SignUpLink>}
          {!isLanding && !isLogin && <Link href="/sign-in">{t('logIn')}</Link>}
          {!isLanding && (
            <>
              <Hr isNavExpanded={isNavExpanded} />
              <SignUpLink href="/">{t('signUp')}</SignUpLink>
            </>
          )}
          <Hr isNavExpanded={isNavExpanded} />
          <NotSureText isNavExpanded={isNavExpanded}>
            {t('notSureWhereTo')}
            <GetHelp
              target="_blank"
              href={`${schoolioBookStoreUrl}concierge/`}
              isNavExpanded={isNavExpanded}
            >
              {t('getHelp')}
            </GetHelp>
          </NotSureText>
        </Links>
      </Main>
    </NavSection>
  )
}
