import { Outlet, Route } from 'react-router-dom'

import { SystemRequirements } from '@/pages/common/SystemRequirements'

import { VerifyEmail } from '../auth/VerifyEmail'
import { AllinOne } from './AllinOne'
import { AutoGrading } from './AutoGrading'
import { CourseInfo } from './CourseInfo'
import { CustomCourses } from './CustomCourses'
import { DigitalAd } from './DigitalAd'
import { LandingCanada } from './LandingCanada'
import { LandingUSA } from './LandingUSA'
import { Learningmodes } from './Learningmodes'
import { Neurodivergence } from './Neurodivergence'
import { Pricing } from './Pricing'
import { PrivacyPolicy } from './PrivacyPolicy'
import { Secular } from './Secular'
import { TermsAndConditions } from './TermsAndConditions'
import { TestingPage } from './TestingPage'

export enum CommonRoute {
  TermsAndConditions = '/terms-and-conditions',
  PrivacyPolicy = '/privacy-policy',
  SystemRequirements = '/system-requirements',
  TestingPage = '/testing-route',
  Ad = 'ad',
  CourseInfo = '/course-info/:courseId',
  VerifyEmail = '/verifyEmail/:code',
  Pricing = '/pricing',
  Usa = '/welcome',
  Canada = '/welcomecanada',
  Neurodivergence = '/neurodivergence',
  AllinOne = '/allinone',
  Secular = '/secular',
  Learningmodes = '/learningmodes',
  AutoGrading = '/autograding',
  CustomCourses = '/customcourses',
}

export const getCommonRoutes = () => (
  <Route element={<Outlet />}>
    <Route path={CommonRoute.Ad} element={<DigitalAd />} />
    <Route path={CommonRoute.CourseInfo} element={<CourseInfo />} />
    <Route
      path={CommonRoute.TermsAndConditions}
      element={<TermsAndConditions />}
    />
    <Route path={CommonRoute.PrivacyPolicy} element={<PrivacyPolicy />} />
    <Route
      path={CommonRoute.SystemRequirements}
      element={<SystemRequirements />}
    />
    <Route path={CommonRoute.TestingPage} element={<TestingPage />} />
    <Route path={CommonRoute.VerifyEmail} element={<VerifyEmail />} />
    <Route path={CommonRoute.Pricing} element={<Pricing />} />
    <Route path={CommonRoute.Usa} element={<LandingUSA />} />
    <Route path={CommonRoute.Canada} element={<LandingCanada />} />
    <Route path={CommonRoute.Neurodivergence} element={<Neurodivergence />} />
    <Route path={CommonRoute.AllinOne} element={<AllinOne />} />
    <Route path={CommonRoute.Secular} element={<Secular />} />
    <Route path={CommonRoute.Learningmodes} element={<Learningmodes />} />
    <Route path={CommonRoute.AutoGrading} element={<AutoGrading />} />
    <Route path={CommonRoute.CustomCourses} element={<CustomCourses />} />
  </Route>
)
