import type { GetParentProfileOutput } from '@graphql/generated/types'
import { useRecoilState } from 'recoil'

import { parentAnalytics } from '@/core/services/analytics'

import { userParentProfile as userProfileAtom } from './atoms'

export const useParentProfile = () => {
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)

  const setProfile = (profile: GetParentProfileOutput) => {
    parentAnalytics.setProfile({
      ...profile,
      role: profile.role.role,
    })
    setUserProfile({
      ...profile,
      currency: profile.countryCode === 'CA' ? 'CAD' : 'USD',
    })
  }

  return {
    profile: userProfile,
    setProfile,
  }
}
