/* eslint-disable complexity */
import { differenceInCalendarDays, format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader } from '@/components'
import { globalTrialDays } from '@/core/const'
import { parentAnalytics } from '@/core/services/analytics'
import {
  MsCategory,
  useAnnualPromotionQuery,
  useCreatePaymentSessionMutation,
  useSubscriptionPlansMscBasedQuery,
} from '@/graphql/generated/types'
import { useChild } from '@/store/parent/child'

import {
  LoadingContainer,
  SaveAnyLine,
  Status,
  StyledStatusLabel,
  TimeWhenBilled,
} from '../SubscribeCard.styled'
import {
  BestValueTag,
  ButtonSelectionSec,
  ButtonsRow,
  DarkAndUnderlineSpan,
  DarkSpan,
  FeaturesTitle,
  LightSpan,
  ListItem,
  ListSec,
  Or,
  PlanDiv,
  PlanPostPriceText,
  PlanPrice,
  PlansDiv,
  PlanTitle,
  PriceTitle,
  SeeLessMoreButton,
  Subscribe,
  SwitcherDiv,
} from './MSCSubscriptionCards.styled'

export const coreBullets = [
  'allFullAccess',
  'coreAccess',
  'coreMath',
  'coreUnlimited',
  'createCustom',
  'autoPilotScheduling',
  'multimediaLessonsActivities',
  'successCoachCheckIns',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export const comboBullets = [
  'allFullAccess',
  'fullAccessAllSubjects',
  'fullAccessFutureReadiness',
  'createCustom',
  'autoPilotScheduling',
  'multimediaLessonsActivities',
  'successCoachCheckIns',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export const futureReadinessBullets = [
  'allFullAccess',
  'entrepreneurship101',
  'socialEmotionalLearning',
  'inDepthFinancialLiteracy',
  'safeSocialMedia',
  'smartConsumerism',
  'introToAIChatGPT',
  'investingBorrowing',
  'shoppingOnline',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

const smallWidthBreakpoint = 970

export function MSCSubscriptionCards() {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })

  const { data: dataMSCPlans, loading: loadingMSCPlans } =
    useSubscriptionPlansMscBasedQuery()
  const { data: dataAnnualPromotion } = useAnnualPromotionQuery()

  const [isAnnual, setIsAnnual] = useState<boolean>(false)
  const [showAll, setShowAll] = useState(false)

  const handleToggle = () => {
    setShowAll(!showAll)
  }

  const { current } = useChild()

  const [createPaymentSession, { loading: createSessionLoading }] =
    useCreatePaymentSessionMutation({
      onCompleted({ createPaymentSession }) {
        window.location.href = createPaymentSession
      },
    })

  // const extractPrice = (priceString: string) => {
  //   const match = /\$([\d.]+)/.exec(priceString)
  //   return match ? parseFloat(match[1]) : 0
  // }

  const [windowWidth, setWindowWidth] = useState(0)
  const resizeWindow = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const prioritySequence =
    windowWidth >= smallWidthBreakpoint
      ? [MsCategory.Core, MsCategory.Combo, MsCategory.FutureReadiness]
      : [MsCategory.Combo, MsCategory.Core, MsCategory.FutureReadiness]

  const filteredPlans = dataMSCPlans?.subscriptionPlansMSCBased
    .filter((plan) =>
      isAnnual
        ? plan.interval === 'Year' &&
          plan.MSCategory !== MsCategory.FutureReadiness
        : plan.interval === 'Month' &&
          plan.MSCategory !== MsCategory.FutureReadiness,
    )
    .sort((a, b) => {
      const aPriority = a.MSCategory
        ? prioritySequence.indexOf(a.MSCategory)
        : prioritySequence.length
      const bPriority = b.MSCategory
        ? prioritySequence.indexOf(b.MSCategory)
        : prioritySequence.length
      return aPriority - bPriority
    })

  // let corePlanPrice = 0
  // let futureReadinessPlanPrice = 0

  // filteredPlans?.forEach((plan) => {
  //   if (plan.MSCategory === 'Core') corePlanPrice = extractPrice(plan.price)
  //   if (plan.MSCategory === 'FutureReadiness')
  //     futureReadinessPlanPrice = extractPrice(plan.price)
  // })

  // const comboIdealPrice =
  //   Math.ceil((corePlanPrice + futureReadinessPlanPrice) * 100) / 100

  if (!current) {
    return null
  }

  return (
    <div>
      <SwitcherDiv>
        <StyledStatusLabel active={!isAnnual}>
          {`${t('month')} ${t('billing')}`}
        </StyledStatusLabel>
        <Status
          isAnnual={isAnnual}
          onClick={() => {
            setIsAnnual(!isAnnual)
          }}
        />
        <StyledStatusLabel active={isAnnual}>
          {`${t('yearly')} ${t('billing')}`}
        </StyledStatusLabel>
      </SwitcherDiv>

      {!loadingMSCPlans && (
        <PlansDiv>
          {filteredPlans?.slice(0, 3).map((plan, index) => (
            <PlanDiv key={plan._id} isFeatured={plan.MSCategory === 'Combo'}>
              {plan.MSCategory === 'Combo' && (
                <BestValueTag>BEST VALUE</BestValueTag>
              )}
              <PlanTitle bgColor={index === 0 ? '0' : index === 1 ? '1' : '2'}>
                <PriceTitle>{t(`${plan.MSCategory ?? ''}`)}</PriceTitle>

                <PlanPrice>
                  {plan.price}
                  {/* {plan.MSCategory === 'Combo' && (
                    <>
                      {' '}
                      <CrossedOutPrice>
                        <del>${comboIdealPrice}</del>
                      </CrossedOutPrice>
                    </>
                  )} */}
                </PlanPrice>

                {plan.MSCategory === MsCategory.Combo && (
                  <p>
                    <LightSpan>
                      The perfect plan for families looking for a{' '}
                      <DarkSpan>complete</DarkSpan> home learning solution.
                    </LightSpan>
                  </p>
                )}
                {plan.MSCategory === MsCategory.Core && (
                  <p>
                    <LightSpan>
                      The perfect plan for families looking for a{' '}
                      <DarkSpan>complete</DarkSpan> home learning solution.
                    </LightSpan>
                  </p>
                )}
              </PlanTitle>
              {plan.MSCategory === MsCategory.Core && (
                <PlanPostPriceText>
                  Unlimited access to the complete Schoolio Library, covering
                  <DarkSpan> Math, Language Arts, Science,</DarkSpan> and{' '}
                  <DarkSpan>Social Studies,</DarkSpan> including{' '}
                  <DarkSpan>interest-based electives,</DarkSpan>{' '}
                  <DarkAndUnderlineSpan>PLUS</DarkAndUnderlineSpan> our{' '}
                  <DarkSpan>Future-Readiness Library</DarkSpan>{' '}
                </PlanPostPriceText>
              )}

              {plan.MSCategory === MsCategory.Combo && (
                <PlanPostPriceText>
                  Unlimited access to the complete Schoolio Library, covering
                  <DarkSpan> Math, Language Arts, Science,</DarkSpan> and{' '}
                  <DarkSpan>Social Studies,</DarkSpan> including{' '}
                  <DarkSpan>interest-based electives,</DarkSpan>{' '}
                  <DarkAndUnderlineSpan>PLUS</DarkAndUnderlineSpan> our{' '}
                  <DarkSpan>Future-Readiness Library</DarkSpan>{' '}
                </PlanPostPriceText>
              )}
              <ButtonsRow>
                {isAnnual &&
                  dataAnnualPromotion?.annualPromotion &&
                  !dataAnnualPromotion?.annualPromotion.isPersonalPromotion && (
                    <ButtonSelectionSec>
                      <Subscribe
                        loading={createSessionLoading}
                        onClick={(event) => {
                          event.preventDefault()

                          createPaymentSession({
                            variables: {
                              childId: current._id,
                              priceId: plan.stripePriceId,
                              couponId: dataAnnualPromotion.annualPromotion
                                ? dataAnnualPromotion.annualPromotion
                                    .stripeCouponId
                                : undefined,
                            },
                          })
                        }}
                      >
                        {t('startNowSave')}
                      </Subscribe>

                      <SaveAnyLine>
                        {
                          dataAnnualPromotion.annualPromotion
                            .labelForFirstSubscription
                        }
                      </SaveAnyLine>

                      <p>
                        {t('payedToday')} <TimeWhenBilled>today</TimeWhenBilled>
                      </p>
                    </ButtonSelectionSec>
                  )}

                {isAnnual &&
                  dataAnnualPromotion?.annualPromotion &&
                  !dataAnnualPromotion?.annualPromotion.isPersonalPromotion && (
                    <Or>OR</Or>
                  )}

                <ButtonSelectionSec>
                  <Subscribe
                    loading={createSessionLoading}
                    onClick={() => {
                      if (
                        current?.isTrialAvailable &&
                        !dataAnnualPromotion?.annualPromotion
                          ?.isPersonalPromotion
                      ) {
                        const trialDays = differenceInCalendarDays(
                          current.trialEndAt
                            ? new Date(current.trialEndAt)
                            : new Date().setDate(
                                new Date().getDate() + globalTrialDays,
                              ),
                          new Date(),
                        )
                        parentAnalytics.emitFreeTrialInit({
                          trialDays,
                          interval: plan.interval,
                        })
                      }

                      createPaymentSession({
                        variables: {
                          childId: current?._id ?? '',
                          priceId: plan.stripePriceId,
                          couponId:
                            dataAnnualPromotion?.annualPromotion
                              ?.isPersonalPromotion && isAnnual
                              ? dataAnnualPromotion.annualPromotion
                                  .stripeCouponId
                              : undefined,
                        },
                      })
                    }}
                  >
                    {t(
                      current?.isTrialAvailable
                        ? 'startFreeTrial'
                        : 'subscribe',
                    )}
                  </Subscribe>

                  {current?.isTrialAvailable && current.trialEndAt && (
                    <p>
                      {t('freeUntil')}{' '}
                      <TimeWhenBilled>
                        {format(new Date(current.trialEndAt), 'MMMM do')}
                      </TimeWhenBilled>
                    </p>
                  )}
                  {current?.isTrialAvailable && !current.trialEndAt && (
                    <p>
                      {t('freeUntil')}{' '}
                      <TimeWhenBilled>
                        {format(
                          new Date().setDate(
                            new Date().getDate() + globalTrialDays,
                          ),
                          'MMMM do',
                        )}
                      </TimeWhenBilled>
                    </p>
                  )}

                  {isAnnual &&
                    dataAnnualPromotion?.annualPromotion &&
                    dataAnnualPromotion.annualPromotion.isPersonalPromotion && (
                      <p>
                        {
                          dataAnnualPromotion.annualPromotion
                            .labelForUpcomingSubscription
                        }
                      </p>
                    )}
                </ButtonSelectionSec>
              </ButtonsRow>
              {plan.MSCategory === MsCategory.Core && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {comboBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}

              {plan.MSCategory === MsCategory.FutureReadiness && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {futureReadinessBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}

              {plan.MSCategory === MsCategory.Combo && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {comboBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}
            </PlanDiv>
          ))}
        </PlansDiv>
      )}

      {loadingMSCPlans && (
        <LoadingContainer>
          <Loader color="grape" />
        </LoadingContainer>
      )}
    </div>
  )
}
