import { useNavigate } from 'react-router-dom'

import { LineListing } from '@/pages/common/Landing/components/LineListing'
import { route } from '@/pages/routes'

import {
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
  TrialButton,
} from '../../CustomCourses.styled'
import { LightSpan, Main, TagLine } from './educationJourney.styled'

const lines: string[] = ['juggling', 'balancing', 'feelingOverwhelmed']

export function EducationJourney() {
  const navigate = useNavigate()

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle style={{ marginBottom: '1rem' }}>
          Discover Schoolio Custom Courses!
        </MainTitle>

        <MainTitle style={{ marginBottom: '2rem' }}>
          <LightSpan>A Unique Education for Every Child</LightSpan>
        </MainTitle>

        <TagLine>No More...</TagLine>
        <LinesContainer>
          <LineListing lines={lines} color="Orange" />
        </LinesContainer>

        <TrialButton onClick={signUp}>
          Start 7-day Free Trial
          <span>*Cancel Anytime | 100% Refunds</span>
        </TrialButton>
      </Main>
    </MaxWidthContainer>
  )
}
