import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

import { Icon } from '@/components'

import {
  AuthNav,
  DiscoverLine,
  FreeTrial,
  GetStarted,
  ImageBackground,
  Main,
  OuterMain,
  PersonName,
  Quote,
  QuoteColumn,
  SchoolioUniverse,
  SignUpBullet,
  SignUpSalesColumn,
  SignUpSalesDiv,
} from './Auth.styled'
import { AuthRoute } from './authRoutes'
import { Footer } from './SignUp/components/Footer/Footer'

export const signupBullets = [
  'hundredsOfCourses',
  'allGradeAccess',
  'videoLessonsQuizzes',
  'pdfDownloadPrint',
  'schedulingExplorationModes',
]

export const signinBullets = [
  'checkOut',
  'seeGrades',
  'workOnline',
  'createCustom',
  'tryScheduling',
]

export function AuthContainer() {
  const { pathname } = useLocation()
  const { t: ts } = useTranslation('signUp')
  const { t: tsi } = useTranslation('signIn')

  const isSignup = pathname === AuthRoute.SignUp
  const isLanding = pathname === AuthRoute.Landing
  const isLogin = pathname === AuthRoute.SignIn

  const signUpSalesDiv = () => {
    return (
      <SignUpSalesColumn>
        <SignUpSalesDiv>
          <GetStarted>{ts('getStarted')}</GetStarted>
          <FreeTrial>{ts('freeTrial')}</FreeTrial>
          <SchoolioUniverse>{ts('joinSchoolio')}</SchoolioUniverse>
          <DiscoverLine>{ts('discover')}</DiscoverLine>

          {signupBullets.map((bullet) => (
            <SignUpBullet key={bullet}>{ts(`${bullet}`)}</SignUpBullet>
          ))}
        </SignUpSalesDiv>
      </SignUpSalesColumn>
    )
  }

  const signUpQuoteDiv = () => {
    return (
      <QuoteColumn>
        <Icon icon="quote" />
        <Quote>{ts('quote')}</Quote>
        <PersonName>{ts('personName')}</PersonName>
      </QuoteColumn>
    )
  }

  const signInInfoDiv = () => {
    return (
      <SignUpSalesColumn>
        <SignUpSalesDiv>
          <GetStarted>{tsi('welcomeBackSign')}</GetStarted>
          <FreeTrial>{tsi('lbDigitalSign')}</FreeTrial>
          <SchoolioUniverse>{tsi('enjoyPersonalized')}</SchoolioUniverse>
          <DiscoverLine>{tsi('dontForget')}</DiscoverLine>

          {signinBullets.map((bullet) => (
            <SignUpBullet key={bullet}>{tsi(`${bullet}`)}</SignUpBullet>
          ))}
        </SignUpSalesDiv>
      </SignUpSalesColumn>
    )
  }

  const signInQuoteDiv = () => {
    return (
      <QuoteColumn>
        <Icon icon="quote" />
        <Quote>{tsi('quote')}</Quote>
        <PersonName>{tsi('personName')}</PersonName>
      </QuoteColumn>
    )
  }

  return (
    <OuterMain>
      {!isLanding && <AuthNav />}
      <Main>
        {!isLanding && <ImageBackground alt="sign in background" />}

        {isSignup && signUpSalesDiv()}
        {isLogin && signInInfoDiv()}

        <Outlet />

        {isSignup && signUpQuoteDiv()}
        {isLogin && signInQuoteDiv()}
      </Main>
      {isSignup && <Footer />}
    </OuterMain>
  )
}
