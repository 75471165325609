import { AppRoute } from '@/pages/AppRoutes'
import type { ChildRoute } from '@/pages/child/childRoute'
import { childRoute } from '@/pages/child/childRoute'
import type { DashboardRoute } from '@/pages/parent/dashboard/dashboardRoute'
import { dashboardRoute } from '@/pages/parent/dashboard/dashboardRoute'
import type { ParentRoute } from '@/pages/parent/parentRoute'
import { parentRoute } from '@/pages/parent/parentRoute'

import type { AdminRoute } from './admin/adminRoute'
import { adminRoute } from './admin/adminRoute'

export type Route<Keys extends Record<string, unknown>> = Record<
  // @ts-expect-error key is string
  Uncapitalize<keyof Keys>,
  (...params: string[]) => string
>

export const replaceParams = (
  pathname: string,
  params: Record<string, string>,
) =>
  pathname.replace(/:[\w\W]+/g, (match) => {
    const param = match.substring(1)

    return params[param]
  })

export const route: Omit<
  Route<typeof AppRoute>,
  'parent' | 'child' | 'admin'
> & {
  parent: { dashboard: Route<typeof DashboardRoute> } & Route<
    typeof ParentRoute
  >
  child: Route<typeof ChildRoute>
  admin: Route<typeof AdminRoute>
} = {
  landing: () => AppRoute.Landing,
  signIn: () => AppRoute.SignIn,
  signUp: () => AppRoute.SignUp,
  forgotPassword: () => AppRoute.ForgotPassword,
  verifyEmail: (code) => replaceParams(AppRoute.VerifyEmail, { code }),
  resetPassword: (token) => replaceParams(AppRoute.ResetPassword, { token }),
  termsAndConditions: () => AppRoute.TermsAndConditions,
  privacyPolicy: () => AppRoute.PrivacyPolicy,
  systemRequirements: () => AppRoute.SystemRequirements,
  testingPage: () => AppRoute.TestingPage,
  ad: () => AppRoute.Ad,
  neurodivergence: () => AppRoute.Neurodivergence,
  allinOne: () => AppRoute.AllinOne,
  secular: () => AppRoute.Secular,
  learningmodes: () => AppRoute.Learningmodes,
  autoGrading: () => AppRoute.AutoGrading,
  customCourses: () => AppRoute.CustomCourses,
  courseInfo: (id) => replaceParams(AppRoute.CourseInfo, { id }),
  pricing: () => AppRoute.Pricing,
  canada: () => AppRoute.Canada,
  usa: () => AppRoute.Usa,
  parent: {
    dashboard: dashboardRoute,
    ...parentRoute,
  },
  child: childRoute,
  admin: adminRoute,
}
