import styled, { css } from 'styled-components'

import { media } from '@/styles/media'
import { flexRow, transitions } from '@/styles/utils'

export type AccordionHeaderSize = 'Big' | 'Small'

export const HeaderContainer = styled.header<{ size: AccordionHeaderSize }>`
  ${flexRow}
  justify-content: space-between;
  padding: ${({ size }) =>
    (size === 'Big' && '2.188rem 2rem') || (size === 'Small' && '0.75rem')};

  ${transitions({})('background')}

  &:hover {
    background: ${({ theme }) => theme.color.babyBlossom};
    border-radius: ${({ size }) =>
      (size === 'Big' && '32px') || (size === 'Small' && '16px')};
  }
`

export const FaqTitle = styled.p`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.small};
    `,
  )};
`

export const ChevronImage = styled.img<{ isExpanded: boolean }>`
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`
