// TabPanel.styled.ts

import styled, { css } from 'styled-components'

import { sectionContainerPadding } from '@/styles/components'
import type { Size } from '@/styles/theme'
import { fadeInAnimation, transitions } from '@/styles/utils'

type WithActiveTab = {
  isActive: boolean
}

type WithDisableAnimation = {
  disableAnimation: boolean
}

export const animationDuration = 300

export const Container = styled.div<
  WithDisableAnimation & { isLoading: boolean }
>`
  padding: ${sectionContainerPadding};
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  ${({ disableAnimation }) =>
    !disableAnimation &&
    css`
      ${transitions({
        duration: animationDuration,
      })('gap')};
    `};
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const TabPanel = styled.div`
  margin-bottom: 1rem;
  position: absolute;
  top: 32px;
  right: 15px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 99;

  @media (max-width: 1100px) {
    top: 10px;
    width: 100%;
  }
`

export const TabButton = styled.button<
  WithActiveTab & { isFirst: boolean; isLast: boolean }
>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.color.grape : 'transparent'};
  color: ${({ isActive, theme }) =>
    isActive ? theme.color.white : theme.color.grape};
  border: 2px solid ${({ theme }) => theme.color.grape};
  padding: 0.5rem 2.1rem;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: 500;
  border-radius: 1.4rem;

  /* Apply transitions for smooth changes */
  transition: background-color 0.3s, color 0.3s, margin-left 0.3s, padding 0.3s;

  ${({ isFirst, isLast, isActive }) => css`
    ${isFirst &&
    !isActive &&
    `
      margin-left: 0;
      padding-right: 3rem;
    `}
    ${isLast &&
    `
      margin-left: -35px;
    `}
    ${!isActive &&
    isLast &&
    `
      padding-left: 3rem;
      margin-left: -35px;
    `}
  `}
`

export const Body = styled.div<WithDisableAnimation & { maxHeight?: Size }>`
  ${fadeInAnimation()};
`
