import { useNavigate } from 'react-router-dom'

import { LineListing } from '@/pages/common/Landing/components/LineListing'
import { route } from '@/pages/routes'

import {
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
  TrialButton,
} from '../../LandingUSA.styled'
import { LightSpan, Main, TagLine } from './educationJourney.styled'

const lines: string[] = ['oneSize', 'fighting', 'feeling', 'worrying']

export function EducationJourney() {
  const navigate = useNavigate()

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle>
          <LightSpan>The</LightSpan> #1 All-In-One Secular{' '}
          <LightSpan>Homeschooling Program</LightSpan>
        </MainTitle>

        <TagLine>No More...</TagLine>
        <LinesContainer>
          <LineListing lines={lines} color="Orange" />
        </LinesContainer>

        <TrialButton onClick={signUp}>
          Start 7-day Free Trial
          <span>*Cancel Anytime | 100% Refunds</span>
        </TrialButton>
      </Main>
    </MaxWidthContainer>
  )
}
