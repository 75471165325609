/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'

import paths from '@/assets/images/landing/paths.svg'
import { FaqsV2 } from '@/components/Faqs/FaqsV2'

import { ImagePaths, MainTitle, MaxWidthContainer } from '../../Landing.styled'
import { Main } from './Faqs.styled'

type FAQ = {
  que: string
  ans: string
}

const faqs1: FAQ[] = [
  {
    que: 'whatAreSubPlans',
    ans: 'couplePricing',
  },
  {
    que: 'mutiChildDiscount',
    ans: 'yesMultiChildDiscount',
  },
  {
    que: 'offerRefunds',
    ans: 'offerUnlimitedFreeTrial',
  },
]

const faqs2: FAQ[] = [
  {
    que: 'findNewFeatures',
    ans: 'notifiedViaEmail',
  },
  {
    que: 'lessonsNotVideos',
    ans: 'lessonsNotVideosAns',
  },
  {
    que: 'systemReuirements',
    ans: 'systemReuirementsAns',
  },
]

const faqs3: FAQ[] = [
  {
    que: 'canISwitch',
    ans: 'canISwitchAns',
  },
  {
    que: 'needGradeMy',
    ans: 'needGradeMyAns',
  },
  {
    que: 'whatPdfDownloads',
    ans: 'whatPdfDownloadsAns',
  },
  {
    que: 'howPdfDownloads',
    ans: 'howPdfDownloadsAns',
  },
  {
    que: 'planAhead',
    ans: 'planAheadAns',
  },
  {
    que: 'followSpecific',
    ans: 'followSpecificAns',
  },
  {
    que: 'progressReport',
    ans: 'progressReportAns',
  },
]

const faqs4: FAQ[] = [
  {
    que: 'followGovernment',
    ans: 'followGovernmentAns',
  },
  {
    que: 'contentSecular',
    ans: 'contentSecularAns',
  },
  {
    que: 'contentImperial',
    ans: 'contentImperialAns',
  },
  {
    que: 'matchCore',
    ans: 'matchCoreAns',
  },
  {
    que: 'highSchool',
    ans: 'highSchoolAns',
  },
]

const faqs5: FAQ[] = [
  {
    que: 'dataCollect',
    ans: 'dataCollectAns',
  },
]

type FaqMaster = {
  title: string
  subFaqs: FAQ[]
}

const faqMaster: FaqMaster[] = [
  {
    title: 'pricingDiscounts',
    subFaqs: faqs1,
  },
  {
    title: 'signingUp',
    subFaqs: faqs2,
  },
  {
    title: 'gradingScheduling',
    subFaqs: faqs3,
  },
  {
    title: 'schoolProgramInfo',
    subFaqs: faqs4,
  },
  {
    title: 'dataUsage',
    subFaqs: faqs5,
  },
]

export function Faqs() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <ImagePaths src={paths} />
        <MainTitle>{t('titles.faq')}</MainTitle>

        <FaqsV2 />

        {/* <FaqContainer>
          {faqMaster.map(function (faqMainEach) {
            return (
              <React.Fragment key={faqMainEach.title}>
                <StyledAccordion
                  disableAnimation
                  defaultExpanded={false}
                  maxHeight="5000rem"
                  headerComponent={({ isExpanded }) => (
                    <HeaderAccordion
                      headerSize="Big"
                      isExpanded={isExpanded}
                      name={t(`faqs.${faqMainEach.title}`)}
                    />
                  )}
                >
                  <Content
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                  >
                    <MainAnswer>
                      {faqMainEach.subFaqs.map(function (faq, i) {
                        return (
                          <React.Fragment key={faq.ans}>
                            <StyledAccordionInner
                              disableAnimation
                              defaultExpanded={false}
                              maxHeight="5000rem"
                              headerComponent={({ isExpanded }) => (
                                <HeaderAccordion
                                  headerSize="Small"
                                  isExpanded={isExpanded}
                                  name={t(`faqs.${faq.que}`)}
                                />
                              )}
                            >
                              <Content
                                onClick={(event) => {
                                  event.stopPropagation()
                                }}
                              >
                                <Answer> {t(`faqs.${faq.ans}`)}</Answer>
                              </Content>
                            </StyledAccordionInner>
                            {i + 1 < faqMainEach.subFaqs.length && <Hr />}
                          </React.Fragment>
                        )
                      })}
                    </MainAnswer>
                  </Content>
                </StyledAccordion>
              </React.Fragment>
            )
          })}
        </FaqContainer> */}
      </Main>
    </MaxWidthContainer>
  )
}
