import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Button, Loader } from '@/components'
import {
  MsCategory,
  useSubscriptionPlansMscBasedQuery,
} from '@/graphql/generated/types'
import {
  comboBullets,
  futureReadinessBullets,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/MSCSubscriptionCards'
import {
  FeaturesTitle,
  ListItem,
  ListSec,
  PlanPostPriceText,
  PlanPrice,
  PlanTitle,
  SeeLessMoreButton,
  SwitcherDiv,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/MSCSubscriptionCards/MSCSubscriptionCards.styled'
import {
  LoadingContainer,
  Status,
  StyledStatusLabel,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/SubscribeCard.styled'
import { route } from '@/pages/routes'
import {
  containerMaxWidth,
  MaxWidthContainer as StyledMaxWidthContainer,
} from '@/styles/components'
import { media } from '@/styles/media'

const MaxWidthContainer = styled(StyledMaxWidthContainer)`
  padding: 0 2rem;
  max-width: ${containerMaxWidth};
  margin: 2rem auto;

  ${media.mobile(
    () => css`
      padding: 0 1rem;
    `,
  )}
`

const SignUpLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  background: linear-gradient(
    98.27deg,
    ${({ theme }) => theme.color.violetMagican} 1.4%,
    ${({ theme }) => theme.color.kingTriton} 183.31%
  );
  padding: 0.2rem 1rem;
  margin: 0px 0px 16px 16px;
  border-radius: 16px;
  display: inline-block;

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.big};
    `,
  )}
`

const Title = styled.div`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.gigantic};

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.big};
      font-size: ${({ theme }) => theme.fontSize.great};
    `,
  )}
`

export const PriceTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.great};
`

const PriceDiv = styled.div`
  padding-top: 2rem;
`

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #4f4f4f;

  ${media.mobile(
    () => css`
      flex-direction: column;
      gap: 1rem;
    `,
  )}
`

const PlanContainer = styled.div<{ index: string }>`
  border: 1px solid #ccc;
  padding: 0;
  width: 30%;
  margin: 10px;

  ${media.mobile(
    () => css`
      width: 100%;
      margin: 0;
    `,
  )}
`

const TagLine = styled.p`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.bigger};
`

const LightSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

const DarkSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.big};
`

const DarkAndUnderlineSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.big};
  text-decoration: underline;
`

const TrialButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 24px;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  line-height: 1.5;
  margin: 1rem auto;
  border-radius: 2rem;

  span {
    display: block;
    font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  }
`

const BestValueTag = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  background-color: ${({ theme }) => theme.color.purpleHeart};
  color: ${({ theme }) => theme.color.white};
  padding: 4px 8px;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 5px;
`

export function PricingComponent() {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })
  const { t: tl } = useTranslation('landing')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isPricing = pathname === '/pricing'

  const { data: dataMSCPlans, loading: loadingMSCPlans } =
    useSubscriptionPlansMscBasedQuery()

  const [isAnnual, setIsAnnual] = useState<boolean>(false)
  const [showAll, setShowAll] = useState(false)

  const handleToggle = () => {
    setShowAll(!showAll)
  }

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  // const extractPrice = (priceString: string) => {
  //   const match = /\$([\d.]+)/.exec(priceString)
  //   return match ? parseFloat(match[1]) : 0
  // }

  const prioritySequence = [
    MsCategory.Core,
    MsCategory.Combo,
    MsCategory.FutureReadiness,
  ]

  // Not showing Future Readiness
  const filteredPlans = dataMSCPlans?.subscriptionPlansMSCBased
    .filter((plan) =>
      isAnnual
        ? plan.interval === 'Year' &&
          plan.MSCategory !== MsCategory.FutureReadiness
        : plan.interval === 'Month' &&
          plan.MSCategory !== MsCategory.FutureReadiness,
    )
    .sort((a, b) => {
      const aPriority = a.MSCategory
        ? prioritySequence.indexOf(a.MSCategory)
        : prioritySequence.length
      const bPriority = b.MSCategory
        ? prioritySequence.indexOf(b.MSCategory)
        : prioritySequence.length
      return aPriority - bPriority
    })

  // let corePlanPrice = 0
  // let futureReadinessPlanPrice = 0

  // filteredPlans?.forEach((plan) => {
  //   if (plan.MSCategory === 'Core') corePlanPrice = extractPrice(plan.price)
  //   if (plan.MSCategory === 'FutureReadiness')
  //     futureReadinessPlanPrice = extractPrice(plan.price)
  // })

  // const comboIdealPrice =
  //   Math.ceil((corePlanPrice + futureReadinessPlanPrice) * 100) / 100

  return (
    <MaxWidthContainer>
      <Title>
        {isPricing && tl('pricing')}
        {!isPricing && 'Affordable Plan!'}
      </Title>
      {isPricing && <TagLine>{tl('getExactly')}</TagLine>}

      <PriceDiv>
        <SwitcherDiv>
          <StyledStatusLabel active={!isAnnual}>
            {`${t('month')} ${t('billing')}`}
          </StyledStatusLabel>
          <Status
            isAnnual={isAnnual}
            onClick={() => {
              setIsAnnual(!isAnnual)
            }}
          />
          <StyledStatusLabel active={isAnnual}>
            {`${t('yearly')} ${t('billing')}`}
          </StyledStatusLabel>
        </SwitcherDiv>

        {!loadingMSCPlans && (
          <PlansContainer>
            {filteredPlans?.slice(0, 3).map((plan, index) => (
              <PlanContainer key={plan._id} index={String(index)}>
                <PlanTitle
                  bgColor={index === 0 ? '0' : index === 1 ? '1' : '2'}
                >
                  <PriceTitle>{t(`${plan.MSCategory ?? ''}`)}</PriceTitle>
                  <PlanPrice>
                    {plan.price}
                    {/* {plan.MSCategory === 'Combo' && (
                      <>
                        {' '}
                        <CrossedOutPrice>
                          <del>${comboIdealPrice}</del>
                        </CrossedOutPrice>
                      </>
                    )} */}
                  </PlanPrice>
                  {plan.MSCategory === MsCategory.Combo && (
                    <>
                      <p>
                        <LightSpan>
                          The perfect plan for families looking for a{' '}
                          <DarkSpan>complete</DarkSpan> home learning solution.
                        </LightSpan>
                      </p>
                      <BestValueTag>BEST VALUE</BestValueTag>
                    </>
                  )}
                  {plan.MSCategory === MsCategory.Core && (
                    <p>
                      <LightSpan>
                        The perfect plan for families looking for a{' '}
                        <DarkSpan>complete</DarkSpan> home learning solution.
                      </LightSpan>
                    </p>
                  )}
                </PlanTitle>
                {plan.MSCategory === MsCategory.Core && (
                  <PlanPostPriceText>
                    Enjoy access to all courses in Unlimited access to the
                    complete Schoolio Library, covering
                    <DarkSpan> Math, Language Arts, Science,</DarkSpan> and{' '}
                    <DarkSpan>Social Studies,</DarkSpan> including{' '}
                    <DarkSpan>interest-based electives,</DarkSpan>{' '}
                    <DarkAndUnderlineSpan>PLUS</DarkAndUnderlineSpan> our{' '}
                    <DarkSpan>Future-Readiness Library</DarkSpan>{' '}
                  </PlanPostPriceText>
                )}

                {plan.MSCategory === MsCategory.Combo && (
                  <PlanPostPriceText>
                    Unlimited access to the complete Schoolio Library, covering
                    <DarkSpan> Math, Language Arts, Science,</DarkSpan> and{' '}
                    <DarkSpan>Social Studies,</DarkSpan> including{' '}
                    <DarkSpan>interest-based electives,</DarkSpan>{' '}
                    <DarkAndUnderlineSpan>PLUS</DarkAndUnderlineSpan> our{' '}
                    <DarkSpan>Future-Readiness Library</DarkSpan>{' '}
                  </PlanPostPriceText>
                )}

                {!isPricing && (
                  <TrialButton onClick={signUp}>
                    Start 7-day Free Trial
                    <span>*Cancel Anytime | 100% Refunds</span>
                  </TrialButton>
                )}
                {isPricing && <SignUpLink href="/">{tl('signUp')}</SignUpLink>}

                {plan.MSCategory === MsCategory.Core && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {comboBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}

                {plan.MSCategory === MsCategory.FutureReadiness && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {futureReadinessBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}

                {plan.MSCategory === MsCategory.Combo && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {comboBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}
              </PlanContainer>
            ))}
          </PlansContainer>
        )}

        {loadingMSCPlans && (
          <LoadingContainer>
            <Loader color="grape" />
          </LoadingContainer>
        )}
      </PriceDiv>
    </MaxWidthContainer>
  )
}
