import React from 'react'
import styled from 'styled-components'

import { HeaderAccordion } from './components'
import {
  Answer,
  Content,
  FaqContainer,
  Hr,
  StyledAccordionInner,
} from './Faqs.styled'

const MainAnswer = styled.div`
  padding: 0 2.125rem;
  background-color: white;
  border-radius: 2rem;
`

type FAQ = {
  que: string
  ans: string
}

const faqs1: FAQ[] = [
  {
    que: 'How much does it cost, and what do I get in the plan?',
    ans: `1. Schoolio Complete

This is the perfect plan for families looking for a complete home learning solution.

- Monthly Subscription: $39.99
- Annual Subscription: $399.99
- Unlimited access to the entire Schoolio library of courses and all grade levels, covering core academics in Math, Language Arts, Science, and Social Studies and interest-based electives, PLUS the one-of-a-kind Schoolio Future Readiness Library, which includes Financial Literacy, Emotional Intelligence & Social Skills, Emerging Technologies, Digital Literacy & Online Safety, and Entrepreneurship.

2. Enterprise Plans

- For schools or groups with 10+ students. Contact Schoolio directly for custom pricing.

Each plan offers valuable resources and flexibility to meet your homeschooling needs!`,
  },
  {
    que: 'Is this program suitable for children with learning differences or special needs?',
    ans: `Absolutely! Schoolio is designed with neurodivergent learners in mind. Our program is designed in 7 unique ways to meet the most common needs of neurodivergent students, while always being flexible and customizable, making it easier to tailor learning to fit different needs and styles. Whether your child is ADHD, Autistic, or has other learning differences, our resources provide an engaging and personalized education experience.

We recognize that every child’s needs are unique, which is why flexibility and customization are at the heart of our approach. You can read more about how our programming is neurodivergent-friendly at https://schoolio.com/neurodivergent-homeschool-program/?.`,
  },
  {
    que: "Is the curriculum aligned with my state's educational standards?",
    ans: `Schoolio is designed as an international curriculum, making it adaptable for use in any country, state, or province. While it is not perfectly aligned with all specific state standards, it is developed with careful consideration of various guidelines, including Common Core, NGSS, TEKS, and other unique standards.

Schoolio aligns well with most standardized learning outcomes, especially in subjects like Mathematics and Language Arts, which follow a consistent progression across regions. Additionally, the curriculum offers both imperial and metric versions to suit your preference and location.

If strict alignment with your state standards is important, we recommend familiarizing yourself with those guidelines and adjusting the Schoolio units as needed. However, even without exact alignment, Schoolio provides a complete, high-quality education with well-rounded and customizable materials, ensuring your student receives an excellent learning experience.

Many Schoolio 8th grade graduates have gone on to public high school in a range of states and provinces, with full success and easy integration.`,
  },
  {
    que: 'Do you offer a discount on multiple students? Scholarships?',
    ans: `Yes, we offer a 30% discount for additional students in a household. However, this discount is not automated, so you’ll need to contact our customer service team at contact@schoolio.com to activate it.

For families or groups with 10 or more students, we also offer Enterprise Pricing—please reach out to inquire about this option.

Additionally, the ClassWallet ESA Funds option is available in selected states. For more info: https://schoolio.com/classwallet/?

At this time, we do not have a scholarship program available.`,
  },
  {
    que: 'What devices can we use?',
    ans: `Schoolio Digital works best on tablets and computers for an optimal experience. While it can be accessed on mobile phones, the platform is not fully optimized for smaller screens. For smooth navigation and usability, we recommend using a tablet or desktop device.`,
  },
]

type FaqComponent = {
  size?: 'Big' | 'Small'
}

export function FaqsV2(options: FaqComponent) {
  return (
    <FaqContainer size={options.size ?? 'Big'}>
      <MainAnswer>
        {faqs1.map(function (faq, i) {
          return (
            <React.Fragment key={faq.ans}>
              <StyledAccordionInner
                disableAnimation
                defaultExpanded={false}
                maxHeight="5000rem"
                size={options.size ?? 'Big'}
                headerComponent={({ isExpanded }) => (
                  <HeaderAccordion
                    headerSize={options.size ?? 'Big'}
                    isExpanded={isExpanded}
                    name={faq.que}
                  />
                )}
              >
                <Content
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <Answer> {faq.ans}</Answer>
                </Content>
              </StyledAccordionInner>
              {i + 1 < faqs1.length && <Hr />}
            </React.Fragment>
          )
        })}
      </MainAnswer>
    </FaqContainer>
  )
}
