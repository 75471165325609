export function TestiminialViedo() {
  return (
    <div
      style={{
        width: 'auto',
        height: '400px',
        position: 'relative',
        margin: '2rem 0',
      }}
    >
      <iframe
        allowFullScreen
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/bZ4PGyhrY8w"
        title="Boarding Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  )
}

export default TestiminialViedo
