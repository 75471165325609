import styled, { css } from 'styled-components'

import { media } from '@/styles/media'

export const Main = styled.div`
  padding: 9.75rem 0;

  ${media.tablet(
    () => css`
      padding: 9.75rem 1rem;
    `,
  )};

  ${media.mobile(
    () => css`
      padding: 8rem 1rem;
    `,
  )};
`

export const ImageContainer = styled.div`
  max-width: 600px;
  margin: auto;
  position: relative;

  ${media.mobile(
    () => css`
      margin: 0 1rem;
    `,
  )};
`

export const ImageDashboard = styled.img`
  box-shadow: 8px 8px 0px ${({ theme }) => theme.color.sweetLucidDreams};
  border-radius: 2rem;
`

export const ImageEmotions = styled.img`
  height: 10rem;
  width: auto;
  position: absolute;
  bottom: 1rem;
  right: -5rem;

  ${media.mobile(
    () => css`
      height: 50%;
      right: -3rem;
    `,
  )};
`

export const ImageTrophy = styled.img`
  position: absolute;
  height: 9rem;
  right: -6rem;
  top: -4rem;

  ${media.tablet(
    () => css`
      height: 6rem;
      right: -2rem;
      top: -16rem;
    `,
  )};

  ${media.mobile(
    () => css`
      top: -18rem;
    `,
  )};
`

export const TagLine = styled.div`
  color: ${({ theme }) => theme.color.classicBouquet};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const SectionImage = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`

export const MainTitle = styled.h1`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
  font-weight: ${({ theme }) => theme.fontWeight.big};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
    font-weight: ${({ theme }) => theme.fontWeight.big};
  }
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.big};
  font-weight: ${({ theme }) => theme.fontWeight.big};
  margin-bottom: 10px;
  margin-top: 35px;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
    font-weight: ${({ theme }) => theme.fontWeight.big};
  }
`

export const MiniTitle = styled.h2`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
    font-weight: ${({ theme }) => theme.fontWeight.big};
  }
`

export const IndiImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 1rem;
  box-shadow: 8px 8px 0px #cab9db;

  @media (min-width: 768px) {
    max-width: 600px;
  }
`

export const UserTestimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const TestimonialImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;

  @media (min-width: 768px) {
    max-width: 230px;
  }
`
export const ReviewContain = styled.h2`
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  @media (min-width: 768px) {
    width: 35%;
  }
`

export const AnchorLink = styled.a`
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  color: #6721b0;
  text-decoration: underline;
`
