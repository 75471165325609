import styled, { css } from 'styled-components'

import stars from '@/assets/images/landing/stars.svg'
import { media } from '@/styles/media'

export const Main = styled.div`
  background: url(${stars}) no-repeat 75% 25%;
  padding-top: 5rem;
  justify-items: center;
  ${media.mobile(
    () => css`
      background-size: 60px;
      background-position: 85% 35%;
    `,
  )}
`

export const TagLine = styled.div`
  color: #cc3333;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const LightSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`
