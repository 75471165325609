import type { SignInUser } from '@graphql/generated/types'
import {
  useResendVerificationEmailMutation,
  UserRole,
  useSignInMutation,
} from '@graphql/generated/types'
import { setFieldErrors } from '@graphql/utils/errors'
import { joiResolver } from '@hookform/resolvers/joi'
import * as Joi from 'joi'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import eye from '@/assets/icons/eye.svg'
import eyeSlash from '@/assets/icons/eye-slash.svg'
import { Button, Checkbox, Input } from '@/components'
import { ConfirmationModal } from '@/components/Modal/ConfirmationModal/ConfirmationModal'
import { useImagePreloader, useRecaptcha, useTitle } from '@/core/hooks'
import { adUserManager } from '@/core/services/adUser'
import { childAnalytics, parentAnalytics } from '@/core/services/analytics'
import { session } from '@/core/services/session'
import { validators } from '@/core/validation'
import { Form, FormColumn, Inputs, Title } from '@/pages/auth/Auth.styled'
import { Transition } from '@/pages/auth/SignIn/components/Transition'
import { imagesToPreload } from '@/pages/auth/SignIn/imagesToPreload'
import { route } from '@/pages/routes'
import { useAuthUser } from '@/store/user'

import {
  Buttons,
  EyeIcon,
  ForgotPassword,
  FormContainer,
  PasswordContainer,
  PasswordInputContainer,
  Separator,
} from './SignIn.styled'

type SignInSchema = {
  usernameOrEmail: string
  password: string
  rememberMe: boolean
}

const schema = Joi.object<SignInSchema>({
  usernameOrEmail: validators.stringRequired(),
  password: validators.password(),
  rememberMe: validators.booleanRequired(),
})

export function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setError,
  } = useForm<SignInSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      usernameOrEmail: '',
      password: '',
      rememberMe: session.getRememberMe() ?? true,
    },
  })

  const navigate = useNavigate()
  const [isEmailVerificationOpen, setIsEmailVerificationOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const initAnalytics = ({
    _id,
    role,
    email,
    username,
    firstName,
    lastName,
  }: SignInUser) => {
    if (role === UserRole.Child) {
      childAnalytics.identify(_id, email, username)
      childAnalytics.emitLogin()
    } else {
      parentAnalytics.identify(_id, email, username)
      parentAnalytics.emitLogin()

      if (window.ViralLoops) {
        window.ViralLoops.getCampaign().then((campaign) => {
          campaign.identify({ email, firstname: firstName, lastname: lastName })
        })
      }
    }
  }

  const [signIn, { loading, data }] = useSignInMutation({
    onCompleted({ signIn: { user } }) {
      session.setRememberMe(getValues('rememberMe'))
      initAnalytics(user)

      if (user.role === UserRole.Child) {
        const lastMoodCheckStart = user.lastMoodCheck?.start

        if (!lastMoodCheckStart) {
          return
        }

        if (new Date(lastMoodCheckStart) < new Date()) {
          return
        }

        setUser(user)
      } else {
        setUser(user)
      }
    },
    onError({ graphQLErrors, message: reason }) {
      const usernameOrEmail = getValues('usernameOrEmail')
      childAnalytics.emitLoginFail({
        reason,
        [usernameOrEmail.includes('@') ? 'email' : 'username']: usernameOrEmail,
      })
      setFieldErrors(graphQLErrors, setError)

      if (reason === 'PARENT_NOT_VERIFIED') {
        setIsEmailVerificationOpen(true)
      }
    },
  })

  const [resendVerificationEmail, { loading: loadingVerification }] =
    useResendVerificationEmailMutation({
      fetchPolicy: 'no-cache',
    })

  const { areImagesPreloaded } = useImagePreloader(imagesToPreload)

  useTitle('signIn')

  const { setUser } = useAuthUser()
  const { getToken, isLoading: isRecaptchaLoading } = useRecaptcha('login')

  const { t } = useTranslation('signIn')

  const onSubmit = async ({ usernameOrEmail, password }: SignInSchema) => {
    const recaptchaToken = await getToken()

    await signIn({
      variables: {
        authCredentials: {
          username: usernameOrEmail,
          password,
          recaptchaToken,
        },
      },
    })
  }

  const onResendVerificationCode = () => {
    const email = getValues('usernameOrEmail')
    resendVerificationEmail({
      variables: {
        usernameOrEmail: email,
      },
      onCompleted(data) {
        if (data) {
          toast.success(t('verificationEmailSentTo') + ' ' + email)
        }

        reset()
        setIsEmailVerificationOpen(false)
      },
      onError() {
        reset()
        setIsEmailVerificationOpen(false)
      },
    })
  }

  const onSignUp = () => {
    navigate(route.landing())
  }

  const isLoading = useMemo(() => {
    if (isRecaptchaLoading) {
      return true
    }

    if (!loading) {
      return false
    }

    return loading || !areImagesPreloaded || isRecaptchaLoading
  }, [loading, areImagesPreloaded, isRecaptchaLoading])

  useEffect(() => {
    adUserManager.handleUrl()
  }, [])

  const isUserChild = data?.signIn.user.role === UserRole.Child

  return (
    <>
      <ConfirmationModal
        body={
          t('resendVerificationEmailTo') +
          ' ' +
          getValues('usernameOrEmail') +
          '?'
        }
        headerLabel={t('emailNotVerified')}
        cancelLabel={t('cancel')}
        confirmLabel={t('resend')}
        isOpen={isEmailVerificationOpen}
        isConfirmationBtnLoading={loadingVerification}
        onClose={() => {
          setIsEmailVerificationOpen(false)
        }}
        onConfirm={onResendVerificationCode}
      />
      {isUserChild && areImagesPreloaded && (
        <Transition
          username={data.signIn.user.username}
          moodCheckId={data.signIn.user.lastMoodCheck?._id}
          onMoodClick={() => {
            setUser(data.signIn.user)
          }}
        />
      )}
      <FormColumn>
        <Title>{t('form.title')}</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <Inputs>
              <Input
                label={t('form.usernameOrEmail')}
                placeholder={t('form.usernameOrEmailPlaceholder')}
                error={errors.usernameOrEmail?.message}
                {...register('usernameOrEmail')}
              />

              <PasswordContainer>
                <PasswordInputContainer>
                  <Input
                    label={t('form.password')}
                    placeholder={t('form.passwordPlaceholder')}
                    error={errors.password?.message}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    {...register('password')}
                  />
                </PasswordInputContainer>

                <EyeIcon
                  src={showPassword ? eyeSlash : eye}
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                />
              </PasswordContainer>
            </Inputs>
            <ForgotPassword to={route.forgotPassword()}>
              {t('form.forgotPassword')}
            </ForgotPassword>
            <Checkbox {...register('rememberMe')}>
              {t('form.rememberMe')}
            </Checkbox>
          </FormContainer>
          <Buttons>
            <Button loading={isLoading}>{t('form.signIn')}</Button>
            <Separator>{t('form.or')}</Separator>
            <Button appearance="outline" onClick={onSignUp}>
              {t('form.signUp')}
            </Button>
          </Buttons>
        </Form>
      </FormColumn>
    </>
  )
}
